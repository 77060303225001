import { useState } from "react";
import { observer } from "mobx-react-lite";
import moment from "moment";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import { Container, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import useAsyncEffect from "use-async-effect";
import _get from "lodash/get";
import classNames from "classnames";

import { authenticationStore } from "../../../stores/authentication-store";
import * as commonMaterial from "../../../materialDesignShared";
import { getDashboard } from "../../../api/dashboard";
import { appointmentStore } from "../../../stores/appointment-store";
import { formOptionsStore } from "../../../stores/form-options-store";
import { EVENTS } from "../../../constants/facebookEvents";
import {
  generateFacebookPixel,
  noScriptInfoFacebookPixel,
} from "../../../helpers/facebook-events-helper";
import { PATHNAMES } from "../../../constants/pathnames";
import { getDepartments } from "../../../api/departments";
import { sonoShortnames } from "../../../constants/sonoShortnames";

import "./Booked.scss";
import { intakeFormStore } from "../../../stores/intake-form-store";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";

const Dropdown = ({ children }) => (
  <div>
    <div className="row">
      <div className="col">{children[0]}</div>
      <div className="col">{children[1]}</div>
    </div>
    <div className="row">
      <div className="col">{children[2]}</div>
      <div className="col">{children[3]}</div>
    </div>
  </div>
);

const Button = ({ children, onClick }) => (
  <button onClick={onClick}>{children}</button>
);

const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);

export const Booked = observer(() => {
  const [appointment, setAppointment] = useState(null);
  const [timeslot, setTimeslot] = useState(null);
  const [virtual, setVirtual] = useState(null);
  const [trackingScript, setTrackingScript] = useState(null);
  const [settings, setSettings] = useState({});
  const [departmentInfo, setDepartmentInfo] = useState();
  const history = useHistoryWithPathBasedReload();

  const appointmentShortname =
    appointment?.shortName || appointment?.appointment_type_shortname;
  const userData = authenticationStore?.getUserData();
  const completed_intake_form = _get(userData, "completed_intake_form", false);
  const selectedDepartment = _get(userData, "department");
  const existTaggedAppointment =
    authenticationStore.getTaggedAppointment() === "true";
  const isGynTypeAppt =
    appointmentShortname === "GVIR" ||
    appointmentShortname === "GYPR" ||
    appointmentShortname === "ANN";
  const isPregnancyCareTypeAppointment =
    appointmentShortname === "VFIR" || appointmentShortname === "VCON";
  const departmentIdStorage = appointmentStore.getDepartmentId();
  const event = {
    startDatetime: moment.utc(timeslot?.start_time).format("YYYYMMDDTHHmmss"),
    endDatetime: moment
      .utc(timeslot?.start_time)
      .add(timeslot?.duration, "minutes")
      .format("YYYYMMDDTHHmmss"),
    duration: timeslot?.duration / 60,
    title: appointment?.card_title,
    location:
      !virtual && departmentInfo
        ? departmentInfo.display_name
        : appointment?.appointment?.virtual_link,
    status: "CONFIRMED",
    busyStatus: "BUSY",
    description:
      "We're looking forward to seeing you! Please log in to your Oula portal to cancel or reschedule this appointment at my.oulahealth.com.",
  };

  const containerClassNames = classNames(
    "location-scheduling-container mobile-container",
    {
      "public confirmation": existTaggedAppointment,
    }
  );

  const titleClassNames = classNames("location-scheduling-title", {
    public: !authenticationStore.userId,
  });

  useAsyncEffect(async () => {
    const { data: dataDashboard } = await getDashboard(
      authenticationStore.userId
    );
    const { data: departmentsInfo } = await getDepartments();

    if (selectedDepartment) {
      setDepartmentInfo(selectedDepartment);
    } else if (departmentIdStorage) {
      const selectedDepartmentInfo = departmentsInfo?.find(
        (department) =>
          department.emr_departmentid === parseInt(departmentIdStorage)
      );

      setDepartmentInfo(selectedDepartmentInfo);
    }

    setSettings(formOptionsStore.formSettingsInfo);
    setAppointment(appointmentStore.getAppointment());
    setTimeslot(appointmentStore.getAppointment()?.timeslot);
    setVirtual(
      appointmentStore.getAppointment()?.card_is_virtual ||
        appointmentStore.getAppointment()?.virtual_link
    );
  }, [appointmentShortname]);

  useAsyncEffect(async () => {
    const hashedState = _get(settings, "data.hashed_state");

    if (hashedState) {
      const { href } = window.location;
      const { SCHEDULE } = EVENTS;
      const { userId, getTaggedAppointment } = authenticationStore;

      if (getTaggedAppointment() === "true") {
        const event = "booked appointment";
        setTrackingScript(
          <Helmet>
            <title>Booked</title>
            <script>
              {`
              //gtag event for ${event}
              gtag('event', 'conversion', {'send_to': 'AW-542953823/d7x9CJiGzPoBEN-i84IC'});
              
              ${generateFacebookPixel(
                SCHEDULE.NAME,
                SCHEDULE.ID,
                href,
                userId,
                hashedState,
                event
              )}
          `}
            </script>
            <noscript>{noScriptInfoFacebookPixel}</noscript>
          </Helmet>
        );
      }
    }
  }, [settings]);

  let departmentDisplay = "";
  const isVirtual = appointment?.virtual;
  const departmentId = departmentInfo?.emr_departmentid;
  const { upperWestSideRedirect } = useFeatureFlag("upper-west-side-redirect");
  const uwsRedirect =
    upperWestSideRedirect && !isVirtual && departmentId === 11;

  if (uwsRedirect) departmentDisplay = "Downtown Manhattan 202 Spring Street";
  else
    departmentDisplay = `${departmentInfo?.department_name} ${departmentInfo?.display_name}`;

  const disableIntakeFormForPostpartum = ["OFPO", "VIRP", "PSTO"].includes(
    appointmentShortname
  );

  return (
    <ThemeProvider theme={commonMaterial.theme}>
      {trackingScript}

      <Container className={containerClassNames}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <div className={titleClassNames}>
            <div className="calendar-icon">
              <img
                className="icon"
                src="/icons/calendar@0,5x.svg"
                alt="calendar-icon"
              />
            </div>
            You are booked
          </div>
          <div
            className="location-container confirmation-container booked-container"
            style={{
              width: "90%",
              maxWidth: "22rem",
              padding: ".5rem",
              borderRadius: "3px",
            }}
          >
            <div className="tag-info">
              <div className="margin-left-15">
                {virtual ? (
                  <div className="virtual-label">Virtual</div>
                ) : (
                  <div className="office-visit-label">Office Visit</div>
                )}
              </div>
            </div>
            {appointment?.appointment_type_description && (
              <div className="card-info">
                {appointment?.appointment_type_description
                  .toLowerCase()
                  .includes("visit")
                  ? appointment?.appointment_type_description
                  : `${appointment?.appointment_type_description} Visit`}
              </div>
            )}
            {!virtual && departmentInfo && (
              <div className="card-info">{departmentDisplay}</div>
            )}
            <div className="time">
              {moment.utc(timeslot?.start_time).format("hh:mm A")} ET
              <br />
              {moment(timeslot?.start_time).format("ddd")},{" "}
              {moment(timeslot?.start_time).format("MMM")}{" "}
              {moment(timeslot?.start_time).format("DD")},{" "}
              {moment(timeslot?.start_time).format("YYYY")}
            </div>
            {virtual && (
              <div className="video-code-container">
                <div className="video-code-title">Virtual link</div>
                <div className="vide-code-link">
                  <a
                    href={
                      appointment.appointment?.virtual_link ||
                      appointment.virtual_link
                    }
                    target="_blank"
                  >
                    Join video appointment
                    <img
                      className="icon"
                      src="/icons/Arrow-Right-Main@0,5x.svg"
                      alt="arrow-right-icon"
                    />
                  </a>
                </div>
              </div>
            )}
            <div className="add-to-calendar">
              <AddToCalendarDropdown
                className="add-to-calendar-button"
                event={event}
              />
            </div>

            <div className="booked-info">
              {/* show intake form if incomplete */}
              {!!appointmentShortname &&
              !completed_intake_form &&
              !disableIntakeFormForPostpartum ? (
                <div>
                  <div>
                    <span className="booked__intakeTitle">
                      INTAKE QUESTIONS
                    </span>
                    <p className="booked__intakeDescription">
                      Please take a moment to complete your intake questions.
                      Your appointment will be confirmed once we've reviewed
                      your responses, and is{" "}
                      <b>
                        subject to cancellation if we don't hear from you within
                        the next week.
                      </b>
                    </p>
                  </div>

                  <div className="complete-intake-form">
                    <commonMaterial.CustomButton
                      variant="outlined"
                      size="large"
                      color="primary"
                      type="submit"
                      style={{ padding: "5px 30px", fontSize: "14px" }}
                      onClick={() => {
                        appointmentStore.removeTaggedAppointment();
                        history.push(PATHNAMES.INTAKE);
                      }}
                    >
                      GET STARTED
                    </commonMaterial.CustomButton>
                  </div>
                </div>
              ) : isGynTypeAppt ? (
                // if intake form is complete and apt type is gyn, prompt to review profile
                <div>
                  <span className={"booked__intakeTitle"}>
                    BEFORE YOUR APPOINTMENT
                  </span>
                  <p className={"booked__intakeDescription"}>
                    We're excited to see you soon! Before your appointment,
                    please take a moment to review your profile and make sure
                    all of your <b>personal and insurance information</b> is
                    correct.
                  </p>
                  <div className={"complete-intake-form"}>
                    <commonMaterial.CustomButton
                      onClick={() => history.push("/my_profile")}
                    >
                      Review Profile
                    </commonMaterial.CustomButton>
                  </div>
                </div>
              ) : // otherwise, don't show anything
              null}
            </div>
          </div>
          {authenticationStore.getTaggedAppointment() === null && (
            <div
              className={`go-back ${
                authenticationStore.userId ? "" : "go-back-auth"
              } scheduling-link`}
              onClick={() => {
                appointmentStore.removeTaggedAppointment();
                history.push(PATHNAMES.SCHEDULING);
              }}
            >
              Go back to scheduling
            </div>
          )}
        </Grid>
      </Container>
    </ThemeProvider>
  );
});
